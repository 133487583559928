import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {DropdownModule} from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AppRoutingModule } from './app-routing.module';
import {PdfViewerModule} from "ng2-pdf-viewer";

@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    PdfViewerModule,
    DropdownModule,
    AppRoutingModule,
    FormsModule,
    ProgressSpinnerModule
  ],
  providers: [{provide: LOCALE_ID, useValue: navigator.language}],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoaderComponent} from "./loader/loader.component";
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import {vehicleModels} from "./availableData";


const routes: Routes = [
  { path: '',
    component: LoaderComponent, },

  {
    path: '1',
    component: LoaderComponent,
    data: { vehicleModel: vehicleModels["smart #1"]}
  },
  {
    path: '3',
    component: LoaderComponent,
    data: { vehicleModel: vehicleModels["smart #3"]}
  },
  {path: '404', component: PagenotfoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
